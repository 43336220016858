import React, { Component } from "react";

import { Box } from "@material-ui/core";

import Image from "material-ui-image";

import { storage } from "../../firebase";

class AsyncImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      mounted: true,
      imageUrl: null,
    };
  }

  async componentDidMount() {
    this.setState({
      mounted: true,
    });

    await this.fetchImageUrl()
  };

  async fetchImageUrl() {
    const { mounted } = this.state;
    const { imagePath, imageName } = this.props;
    const fullImagePath = imagePath + imageName;

    if (mounted) {
      storage
        .ref(fullImagePath)
        .getDownloadURL()
        .then((url) => {
          this.setState({
            imageUrl: url,
            loading: false,
          });
        })
        .catch((error) =>{
          this.setState({
            loading: false,
          });
        });
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.imageName !== prevProps.imageName) {
      this.setState({
        loading: true,
      });

      await this.fetchImageUrl()
    }
  }

  componentWillUnmount() {
    this.setState({
      mounted: false,
    });
  }

  render() {
    const { loading, mounted, imageUrl } = this.state;
    const {
      imageHeight,
      animationDuration = 0,
    } = this.props;

    if (!mounted) {
      return null;
    }

    if (loading) {
      return (
        <Box style={{
          height: imageHeight,
        }}/>
      )
    }

    return (
      <Box style={{
        height: imageHeight,
      }}>
        <Image
          src={imageUrl}
          animationDuration={animationDuration}
          cover
          iconContainerStyle={{
            height: imageHeight,
          }}
          imageStyle={{
            height: imageHeight,
          }}
          style={{
            paddingTop: 0,
          }}
        />
      </Box>
    );
  }
}

export default AsyncImage;
