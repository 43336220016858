import React, { Component } from "react";

import moment from "moment";

import {
  Grid,
  Box,
  Typography,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import { CountdownCircleTimer } from "react-countdown-circle-timer";

const styles = (theme) => ({
});

const timerProps = {
  isPlaying: true,
  size: 140,
  strokeWidth: 6,
};

const renderTime = (dimension, time) => {
  return (
    <Box textAlign="center">
      <Typography variant="h3">
        {time}
      </Typography>
      <Typography variant="h5">
        {dimension}
      </Typography>
    </Box>
  );
};

const minuteSeconds = 60;
const hourSeconds = 3600;
const daySeconds = 86400;

const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
const getTimeDays = (time) => (time / daySeconds) | 0;

class Countdown extends Component {
  render() {
    // Styling
    const { classes } = this.props;

    const {
      endTime: endTimeString,
    } = this.props;

    const startTime = moment(); // use UNIX timestamp in seconds
    const endTime = moment(endTimeString);
    const duration = moment.duration(endTime.diff(startTime));

    const remainingTime = duration.asSeconds();
    const days = Math.ceil(remainingTime / daySeconds);
    const daysDuration = days * daySeconds;

    return (
          <Box mx={10} display="flex" justifyContent="space-evenly">
            <CountdownCircleTimer
              {...timerProps}
              colors={[["#7E2E84"]]}
              duration={daysDuration}
              initialRemainingTime={remainingTime}
            >
              {({ elapsedTime }) =>
                renderTime("days", getTimeDays(daysDuration - elapsedTime))
              }        
            </CountdownCircleTimer>
            <CountdownCircleTimer
              {...timerProps}
              colors={[["#D14081"]]}
              duration={daySeconds}
              initialRemainingTime={remainingTime % daySeconds}
              onComplete={(totalElapsedTime) => [
                remainingTime - totalElapsedTime > hourSeconds
              ]}
            >
              {({ elapsedTime }) =>
                renderTime("hours", getTimeHours(daySeconds - elapsedTime))
              }
            </CountdownCircleTimer>
            <CountdownCircleTimer
              {...timerProps}
              colors={[["#EF798A"]]}
              duration={hourSeconds}
              initialRemainingTime={remainingTime % hourSeconds}
              onComplete={(totalElapsedTime) => [
                remainingTime - totalElapsedTime > minuteSeconds
              ]}
            >
              {({ elapsedTime }) =>
                renderTime("minutes", getTimeMinutes(hourSeconds - elapsedTime))
              }
            </CountdownCircleTimer>
            <CountdownCircleTimer
              {...timerProps}
              colors={[["#218380"]]}
              duration={minuteSeconds}
              initialRemainingTime={remainingTime % minuteSeconds}
              onComplete={(totalElapsedTime) => [
                remainingTime - totalElapsedTime > 0
              ]}
            >
              {({ elapsedTime }) =>
                renderTime("seconds", getTimeSeconds(elapsedTime))
              }
            </CountdownCircleTimer>
          </Box>
    );
  }
}

export default withStyles(styles)(Countdown);
