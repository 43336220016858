import React, { Component } from "react";

import { Card, CardContent, CardMedia, Box, Typography } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import EmptyState from "../EmptyState";

import AsyncImage from "../AsyncImage";

import { ReactComponent as NoDataIllustration } from "../../assets/illustrations/no-data.svg";

const styles = (theme) => ({
  root: {
    //position: 'sticky',
    //top: `calc(${theme.mixins.toolbar.minHeight}px + ${theme.spacing(3.5)}px)`,
  },
});

class ContestantDetailsPanel extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    // Styling
    const { classes } = this.props;

    const { selectedContestant } = this.props;

    return (
      <Card className={classes.root}>
        {selectedContestant && (
          <>
            <AsyncImage
              imagePath="/images/contestants/"
              imageName={selectedContestant.fullImageName}
              imageHeight={200}
            />
            <CardContent>
              <Box px={2} pb={2}>
                <Typography variant="h4" gutterBottom>
                  {selectedContestant.name}, {selectedContestant.age}
                </Typography>
                <Typography variant="subtitle2">
                  {selectedContestant.occupation}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary" paragraph>
                  {selectedContestant.hometown}
                </Typography>
                {selectedContestant.biography.allieDescription && (
                  <>
                    <Typography variant="subtitle2">
                      Allie's Week 1 Summary:
                    </Typography>
                    <Typography variant="body1" paragraph>
                      {selectedContestant.biography.allieDescription}
                    </Typography>
                  </>
                )}
                <Typography variant="subtitle2">
                  ABC's Description:
                </Typography>
                <Typography variant="body1">
                  {selectedContestant.biography.description}
                </Typography>
                <Box pt={2}>
                  {selectedContestant.biography.likes.map((sentence, index) => 
                    <Typography variant="body1" key={index}>
                      • {sentence}
                    </Typography>
                  )}
                </Box>
              </Box>
            </CardContent>
          </>
        )}

        {!selectedContestant && (
          <EmptyState
            type="card"
            image={<NoDataIllustration />}
            title="No Contestant Selected"
            description="Select a contestant to learn more about them!"
          />
        )}
      </Card>
    );
  }
}

export default withStyles(styles)(ContestantDetailsPanel);
