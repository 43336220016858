import { auth, firestore, } from "../firebase";

import { isLoaded, } from "react-redux-firebase";

import {
  currentSeason,
  setupSteps,
  rankings,
  seasonPredictions,
} from "../data/season-constants";

import {
  seasonPredictionsQuestionsBySeason
} from "../data/season-predictions-data";

const userEntries = {};

userEntries.updateRankings = (userEntry, rankings, openSnackbar) => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));
      return;
    }

    const uid = currentUser.uid;

    if (!uid) {
      reject(new Error("No current user"));
      return;
    }

    if (userEntry) {
      const userEntryRef = firestore.collection("seasons").doc(currentSeason)
        .collection("user_entries").doc(userEntry.id);

      userEntryRef.set({ 
          rankings: rankings,
        },
        { merge: true }
      )
      .then(() => {
        openSnackbar("Saved rankings!", "success")
      })
      .catch((reason) => {
        reject(reason);
      });
    } else {
      firestore.collection("seasons").doc(currentSeason).collection("user_entries")
        .add({ 
          rankings: rankings,
          userId: uid,
        })
        .then(() => {
          openSnackbar("Saved rankings!", "success")
        })
        .catch((reason) => {
          reject(reason);
        });
    }
  });
};

userEntries.updateSeasonPredictions = (userEntry, seasonPredictions, openSnackbar) => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));
      return;
    }

    const uid = currentUser.uid;

    if (!uid) {
      reject(new Error("No current user"));
      return;
    }

    let filteredSeasonPredictions = {}, questionId;
    for (questionId in seasonPredictions) {
      if (seasonPredictions[questionId] !== "") {
        filteredSeasonPredictions[questionId] = seasonPredictions[questionId]; 
      }
    }

    if (userEntry) {
      const userEntryRef = firestore.collection("seasons").doc(currentSeason)
        .collection("user_entries").doc(userEntry.id);

      userEntryRef.set({ 
          seasonPredictions: filteredSeasonPredictions,
        },
        { merge: true }
      )
      .then(() => {
        openSnackbar("Saved season predictions!", "success")
      })
      .catch((reason) => {
        reject(reason);
      });
    } else {
      firestore.collection("seasons").doc(currentSeason).collection("user_entries")
        .add({ 
          seasonPredictions: filteredSeasonPredictions,
          userId: uid,
        })
        .then(() => {
          openSnackbar("Saved season predictions!", "success")
        })
        .catch((reason) => {
          reject(reason);
        });
    }
  });
};

userEntries.updateWeeklyPredictions = (userEntry, weeklyPredictions, openSnackbar) => {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject(new Error("No current user"));
      return;
    }

    const uid = currentUser.uid;

    if (!uid) {
      reject(new Error("No current user"));
      return;
    }

    if (userEntry) {
      const userEntryRef = firestore.collection("seasons").doc(currentSeason)
        .collection("user_entries").doc(userEntry.id);

      userEntryRef.set({ 
          weeklyPredictions: weeklyPredictions,
        },
        { merge: true }
      )
      .then(() => {
        openSnackbar("Saved weekly predictions!", "success")
      })
      .catch((reason) => {
        reject(reason);
      });
    } else {
      firestore.collection("seasons").doc(currentSeason).collection("user_entries")
        .add({ 
          weeklyPredictions: weeklyPredictions,
          userId: uid,
        })
        .then(() => {
          openSnackbar("Saved weekly predictions!", "success")
        })
        .catch((reason) => {
          reject(reason);
        });
    }
  });
};

userEntries.isStepComplete = (step, userEntry, season) => {
  if (!userEntry || !season) {
    return false;
  }

  switch (step) {
    case rankings:
      return userEntry.rankings !== undefined && userEntry.rankings.length == season.numContestants;
    case seasonPredictions:
      return userEntry.seasonPredictions !== undefined && Object.keys(userEntry.seasonPredictions).length == season.seasonPredictionsQuestions.allIds.length;
  }
}

userEntries.isRankingComplete = (myUserEntryAll, seasons) => {
  const myUserEntry = userEntries.getMyUserEntryFromAllOrDefault(myUserEntryAll);
  const season = seasons === undefined ? undefined : seasons[currentSeason];

  return userEntries.isStepComplete(rankings, myUserEntry, season);
};

userEntries.getIncompleteSetupSteps = (userEntry, season) => {
  if (!userEntry) {
    return setupSteps;
  } else {
    return setupSteps.filter(step => !this.isStepComplete(step, userEntry, season))
  }
};

userEntries.allStepsComplete = (myUserEntryAll, seasons) => {
  const myUserEntry = userEntries.getMyUserEntryFromAllOrDefault(myUserEntryAll);
  const season = seasons === undefined ? undefined : seasons[currentSeason];

  if (!myUserEntry || !season) {
    return false;
  } else {
    return userEntries.isStepComplete(rankings, myUserEntry, season)
      && userEntries.isStepComplete(seasonPredictions, myUserEntry, season);
  }
};

userEntries.getMyUserEntryFromAllOrDefault = (myUserEntryAll) => {
  if (!isLoaded(myUserEntryAll) || myUserEntryAll === undefined || myUserEntryAll.length == 0) {
    return {};
  }

  return myUserEntryAll[0];
};

userEntries.getCurrentSeasonOrDefault = (seasons) => {
  if (!isLoaded(seasons) || seasons === undefined) {
    return {};
  }

  return seasons[currentSeason];
};

// Admin Page


// Weekly predictions
// Button to add new weekly predictions week
// Expands into form to add new weekly predictions questions
// Save button saves weekly predictions questions and increments current weekly predictions week

// After creation, each question becomes editable answer key
// select type of answer, form fields based on that
// Save button for each question also scores

// Scoring anything also updates weekly and overall rankings

// Standings
// check boxes for which columns to show?

export default userEntries;