import React, { Component } from "react";

import { compose, } from "recompose";

import { Link as RouterLink, withRouter, matchPath, } from "react-router-dom";

import {
  Box,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import { fade } from "@material-ui/core/styles/colorManipulator";

import appearance from "../../services/appearance";

const styles = (theme) => ({
  listItem: {
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    "&.Mui-selected": {
      backgroundColor: fade(theme.palette.primary[500], 0.2),
      fontWeight: 500,
      "&:hover": {
        backgroundColor: fade(theme.palette.primary[500], 0.2),
      }
    }
  },
  listItemText: {
    fontWeight: "inherit",
  },
});

class SidebarLink extends Component {
  isActiveRoute = (routeName)  => {
    return matchPath(routeName, {
      path: this.props.location.pathname,
      exact: true,
      strict: false,
    }) !== null;
  }

  render() {
    // Styling
    const { classes } = this.props;

    const {
      baseUrl,
      routeData,
      nested,
    } = this.props;

    const {
      path,
      icon,
      sidebarName,
    } = routeData;

    const pathToUse = `${baseUrl}${path}`;

    return (
      <ListItem
        button
        component={RouterLink}
        to={pathToUse}
        selected={this.isActiveRoute(pathToUse)}
        className={classes.listItem}
      >
        {nested && (
          <Box pl={4}/>
        )}
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText primary={sidebarName} classes={{primary: classes.listItemText}} />
      </ListItem>
    );
  };
}

export default compose(
  withStyles(styles),
  withRouter,
)(SidebarLink);
