import React, { Component, useEffect } from "react";

import PropTypes from "prop-types";

import { compose } from 'redux'

import { connect } from 'react-redux'

import { firestoreConstants, } from "../../firebase";

import { firestoreConnect } from 'react-redux-firebase'

import { get } from "lodash";

import { BrowserRouter, Switch, Redirect, Route, useRouteMatch, useLocation, } from "react-router-dom";

import { Toolbar, Box, Container, } from "@material-ui/core";

import HomePage from "../HomePage";
import UserPage from "../UserPage";
import UserPicksPage from "../UserPicksPage";
import SeasonPredictionsPage from "../SeasonPredictionsPage";
import StandingsPage from "../StandingsPage";
import WeeklyPredictionsPage from "../WeeklyPredictionsPage";
import NotFoundPage from "../NotFoundPage";

import UserEntryPage from "../../features/userEntryPage/UserEntryPage";
import AdminPage from "../../features/admin/AdminPage";

import Sidebar from "../../features/navigation/Sidebar";
import ScrollToTop from "../ScrollToTop";

import {
  selectCurrentSeason,
} from '../../features/navigation/navigationSlice';

import {
  defaultSeason,
} from "../../data/season-constants";

import routes, {
  HOME,
  STANDINGS,
  RANKS,
  SEASON_PREDICTIONS,
  USER_ENTRIES,
  MY_ENTRY,
  WEEKLY_PREDICTIONS,
  SEASONS,
  ADMIN,
} from "../../data/routes";

import appearance from "../../services/appearance";

const isGoldenBachelor = (url) => url.includes("golden_");

function SeasonRouter(props) {
  const match = useRouteMatch();
  const location = useLocation();

  useEffect(() => {
    const {
      setTheme,
    } = props;

    if (isGoldenBachelor(location.pathname)) {
      setTheme(appearance.goldenTheme);
    } else {
      setTheme(appearance.defaultTheme);
    }
  }, [location]);

  // Properties
  const {
    user,
    roles,
  } = props;

  // Functions
  const {
    openSnackbar,
    onSignUpClick,
    onSignInClick,
    onSettingsClick,
    setTheme,
  } = props;

  return (
    <Switch>
      <Route exact path={`${match.path}/`}>
        <HomePage
          user={user}
          openSnackbar={openSnackbar}
          onSignUpClick={onSignUpClick}
          onSignInClick={onSignInClick}
          onSettingsClick={onSettingsClick}
          baseUrl={match.url}
        />
      </Route>

      <Route exact path={`${match.path}${routes[ADMIN].path}`}>
        {user && roles.includes("admin") ? (
          <AdminPage
            openSnackbar={openSnackbar}
          />
        ) : (
          <Redirect to={routes[HOME].path} />
        )}
      </Route>

      <Route exact path={`${match.path}${routes[STANDINGS].path}`}>
        {user ?
          <StandingsPage
            user={user}
            onSettingsClick={onSettingsClick}
            baseUrl={match.url}
          /> :<Redirect to={routes[HOME].path} />
        }
      </Route>

      <Route exact path={`${match.path}${routes[MY_ENTRY].path}`}>
        {user ?
          <UserEntryPage
            user={user}
            baseUrl={match.url}
          /> : <Redirect to={routes[HOME].path} />}
      </Route>

      <Route exact path={`${match.path}${routes[USER_ENTRIES].path}`}>
        {user ? <UserEntryPage /> : <Redirect to={routes[HOME].path} />}
      </Route>

      <Route exact path={`${match.path}${routes[WEEKLY_PREDICTIONS].path}`}>
        {user ? 
          <WeeklyPredictionsPage
            user={user} 
            openSnackbar={openSnackbar}
          /> : <Redirect to={routes[HOME].path} />}
      </Route>

      <Route exact path={`${match.path}${routes[RANKS].path}`}>
        {user ?
          <UserPicksPage user={user}
            openSnackbar={openSnackbar}
          /> : <Redirect to={routes[HOME].path} />}
      </Route>

      <Route exact path={`${match.path}${routes[SEASON_PREDICTIONS].path}`}>
        {user ?
          <SeasonPredictionsPage user={user}
            openSnackbar={openSnackbar}
          /> : <Redirect to={routes[HOME].path} />}
      </Route>

      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  );
}

class Router extends Component {
  render() {
    // Properties
    const {
      user,
      roles,
      bar,
    } = this.props;

    // Functions
    const {
      openSnackbar,
      onSignUpClick,
      onSignInClick,
      onSettingsClick,
      setTheme,
     } = this.props;

    const {
      currentSeason,
    } = this.props;

    return (
      <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
        {bar}
        {user && (
          <Sidebar />
        )}
        <Container disableGutters maxWidth="lg">
          <Box flex={1}>
            <Toolbar />
            <ScrollToTop />
            <Switch>
              <Route exact path={routes[HOME].path}>
                {user 
                  ? <Redirect to={routes[SEASONS].pathWithoutParam + currentSeason} />
                  : <Redirect to={routes[SEASONS].pathWithoutParam + defaultSeason} />
                }
              </Route>

              <Route
                path={routes[SEASONS].path}
              >
                <SeasonRouter
                  user={user}
                  roles={roles}
                  openSnackbar={openSnackbar}
                  onSignUpClick={onSignUpClick}
                  onSignInClick={onSignInClick}
                  onSettingsClick={onSettingsClick}
                  setTheme={setTheme}
                />
              </Route>
              <Route>
                <NotFoundPage />
              </Route>
            </Switch>
            </Box>
          </Container>
      </BrowserRouter>
    );
  }
}

Router.propTypes = {
  // Properties
  user: PropTypes.object,
  roles: PropTypes.array.isRequired,
  bar: PropTypes.element,

  // Functions
  openSnackbar: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    currentSeason: selectCurrentSeason(state),
  };
}

export default compose(
  connect(mapStateToProps),
)(Router);
