import React, { Component } from "react";

import { compose } from 'redux'

import { connect } from 'react-redux'

import { withRouter, } from "react-router-dom";

import { get } from "lodash";

import {
  firestoreConnect,
  populate,
  isLoaded,
} from 'react-redux-firebase'

import { auth, firestoreConstants, } from "../../firebase";

import {
  Box,
  Paper,
  Button,
  Collapse,
  Tabs,
  Tab,
  Hidden,
} from "@material-ui/core";

import {
  Alert,
} from "@material-ui/lab";

import { withStyles } from "@material-ui/core/styles";

import { fade } from "@material-ui/core/styles/colorManipulator";

import MUIDataTable from "mui-datatables";

import RankChangeCell from "../RankChangeCell";

import DisplayNameCell from "../DisplayNameCell";

import WinnerCell from "../WinnerCell";

import TabPanel from "../TabPanel";

import Loader from "../Loader";

import {
  myUserEntry,
} from "../../data/season-constants";

import {
  contestantDataBySeason,
} from "../../data/contestant-data";

import appearance from "../../services/appearance";

import userEntries from "../../services/user-entries";

const claytonColumns = [
  { label: 'Rank', field: 'name', type: 'numeric' },
  { label: 'Entry, Owner', field: 'surname' },
  { label: 'Winner', field: 'imageUrl', render: rowData => <img src={rowData.imageUrl} style={{width: 40, borderRadius: '50%'}}/> },
];

const styles = (theme) => ({
  myEntry: {
    backgroundColor: fade(appearance.colors.yellow.import[500], 0.2),
  },
  contestantWon: {
    backgroundColor: "#badfbb",
  },
  eliminated: {
    backgroundColor: "#fedbd8",
  },
});

class StandingsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTabIndex: 0,
    };
  }
  
  sortWinners = (order) => {
    return (obj1, obj2) => {
      const name1 = get(obj1, ["data", "name"]);
      const name2 = get(obj2, ["data", "name"]);

      if (obj1 === obj2) {
        return 0;
      }

      if (!name1) {
        return 1;
      }

      if (!name2) {
        return -1;
      }

      if (order === "asc") {
        return name1.localeCompare(name2);
      }

      if (order === "desc") {
        return name2.localeCompare(name1);
      }
    }
  }


  getWinnerBackgroundClassName = (isWinner, isEliminated) => {
    const { classes } = this.props;

    if (isWinner) {
      return classes.contestantWon;
    } else if (isEliminated) {
      return classes.eliminated;
    }

    return "";
  };

  getDisplayName = (dbUser) => {
    const {
      id,
      uid,
      firstName,
      lastName,
    } = dbUser;

    let realName = firstName;

    if (lastName) {
      realName = realName + " " + lastName;
    }

    if (realName) {
      return realName;
    }

    const nameToUse = uid || id;
    return nameToUse.substring(0, 6);
  }

  getColumnsBySeason = (season) => {
    const {
      baseUrl
    } = this.props;

    const {
      id: seasonId,
      allWeekIds,
      currentWeek,
      isSeasonOver = false,
    } = season;

    switch (seasonId) {
      case "katie_2021": {
        return [
          { label: 'Original Data', name: 'originalData', options: {
            display: false,
            filter: false,
            sort: false,
          }},
          { label: 'Rank', name: 'rank', type: 'numeric', options: {
            sortThirdClickReset: true, 
          }},
          { label: '1-Week Change', name: 'rankChange', 
            options: {
              sortDescFirst: true, 
              sortThirdClickReset: true, 
              customBodyRender: ((value) => (
                <RankChangeCell
                  rankChange={value}
                />
              )),
            }
          },
          { label: 'Entry, Owner', name: 'displayName', options: {
            sortThirdClickReset: true, 
              customBodyRender: ((value, tableMeta) => (
                <DisplayNameCell
                  displayName={value}
                  userEntryId={tableMeta.rowData[0].userEntryId}
                  baseUrl={baseUrl}
                />
              )),
            }
          },
          { label: 'Week 3', name: 'week3Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true,
          } },
          { label: 'Week 4', name: 'week4Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Week 5', name: 'week5Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Week 6', name: 'week6Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Week 7', name: 'week7Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Week 8', name: 'week8Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Week 9', name: 'week9Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Week 10', name: 'week10Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Winner', name: 'winner',
            options: {
              sortCompare: this.sortWinners,
              customBodyRender: ((value, tableMeta) => (
                <WinnerCell
                  winner={tableMeta.rowData[0].winner}
                  value={value}
                />
              )),
              setCellProps: (value) => {
                const winner = value.props.value;
                return {
                  className: this.getWinnerBackgroundClassName(
                    winner.didWinnerWin,
                    winner.isWinnerEliminated,
                  )
                }
              }
            }
          },
          { label: 'Season Predictions', name: 'seasonPredictionPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Total', name: 'overallPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
        ]
      }
      case "michelle_2021": {
        return [
          { label: 'Original Data', name: 'originalData', options: {
            display: false,
            filter: false,
            sort: false,
          }},
          { label: 'Rank', name: 'rank', type: 'numeric', options: {
            sortThirdClickReset: true, 
          }},
          { label: '1-Week Change', name: 'rankChange', 
            options: {
              sortDescFirst: true, 
              sortThirdClickReset: true, 
              customBodyRender: ((value) => (
                <RankChangeCell
                  rankChange={value}
                />
              )),
            }
          },
          { label: 'Entry, Owner', name: 'displayName', options: {
            sortThirdClickReset: true, 
              customBodyRender: ((value, tableMeta) => (
                <DisplayNameCell
                  displayName={value}
                  userEntryId={tableMeta.rowData[0].userEntryId}
                  baseUrl={baseUrl}
                />
              )),
            }
          },
          { label: 'Week 2', name: 'week2Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true,
          } },
          { label: 'Week 3', name: 'week3Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true,
          } },
          { label: 'Week 4', name: 'week4Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Week 5', name: 'week5Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Week 6', name: 'week6Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Week 7', name: 'week7Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Week 8', name: 'week8Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Week 9', name: 'week9Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Winner', name: 'winner',
            options: {
              sortCompare: this.sortWinners,
              customBodyRender: ((value, tableMeta) => (
                <WinnerCell
                  winner={tableMeta.rowData[0].winner}
                  value={value}
                />
              )),
              setCellProps: (value) => {
                const winner = value.props.value;
                return {
                  className: this.getWinnerBackgroundClassName(
                    winner.didWinnerWin,
                    winner.isWinnerEliminated,
                  )
                }
              }
            }
          },
          { label: 'Season Predictions', name: 'seasonPredictionPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Total', name: 'overallPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
        ]
      }
      case "clayton_2022": {
        return [
          { label: 'Original Data', name: 'originalData', options: {
            display: false,
            filter: false,
            sort: false,
          }},
          { label: 'Rank', name: 'rank', type: 'numeric', options: {
            sortThirdClickReset: true, 
          }},
          { label: 'Entry, Owner', name: 'displayName', options: {
            sortThirdClickReset: true, 
              customBodyRender: ((value, tableMeta) => (
                <DisplayNameCell
                  displayName={value}
                  userEntryId={tableMeta.rowData[0].userEntryId}
                  baseUrl={baseUrl}
                />
              )),
            }
          },
          { label: 'Total', name: 'overallPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Winner', name: 'winner',
            options: {
              sortThirdClickReset: true, 
              customBodyRender: ((value, tableMeta) => (
                <WinnerCell
                  winner={tableMeta.rowData[0].winner}
                  value={value}
                />
              )),
              setCellProps: (value) => {
                const winner = value.props.value;
                return {
                  className: this.getWinnerBackgroundClassName(
                    winner.didWinnerWin,
                    winner.isWinnerEliminated,
                  )
                }
              },
              sortCompare: this.sortWinners,
            }
          },
          { label: 'Week 3', name: 'week3Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true,
          } },
          { label: 'Week 4', name: 'week4Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Week 5', name: 'week5Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Week 6', name: 'week6Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Week 7', name: 'week7Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Week 8', name: 'week8Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Week 9', name: 'week9Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Week 10', name: 'week10Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Season Predictions', name: 'seasonPredictionPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
        ]
      }
      case "gabby_rachel_2022": {
        return [
          { label: 'Original Data', name: 'originalData', options: {
            display: false,
            filter: false,
            sort: false,
          }},
          { label: 'Rank', name: 'rank', type: 'numeric', options: {
            sortThirdClickReset: true, 
          }},
          { label: 'Entry, Owner', name: 'displayName', options: {
            sortThirdClickReset: true, 
              customBodyRender: ((value, tableMeta) => (
                <DisplayNameCell
                  displayName={value}
                  userEntryId={tableMeta.rowData[0].userEntryId}
                  baseUrl={baseUrl}
                />
              )),
            }
          },
          { label: 'Winner', name: 'winner',
            options: {
              sortThirdClickReset: true, 
              customBodyRender: ((value, tableMeta) => (
                <WinnerCell
                  winner={tableMeta.rowData[0].winner}
                  value={value}
                />
              )),
              setCellProps: (value) => {
                const winner = value.props.value;
                return {
                  className: this.getWinnerBackgroundClassName(
                    winner.didWinnerWin,
                    winner.isWinnerEliminated,
                  )
                }
              },
              sortCompare: this.sortWinners,
            }
          },
        ]
      }
      case "zach_2023": {
        return [
          { label: 'Original Data', name: 'originalData', options: {
            display: false,
            filter: false,
            sort: false,
          }},
          { label: 'Rank', name: 'rank', type: 'numeric', options: {
            sortThirdClickReset: true, 
          }},
          { label: 'Entry, Owner', name: 'displayName', options: {
            sortThirdClickReset: true, 
              customBodyRender: ((value, tableMeta) => (
                <DisplayNameCell
                  displayName={value}
                  userEntryId={tableMeta.rowData[0].userEntryId}
                  baseUrl={baseUrl}
                />
              )),
            }
          },
          { label: 'Winner', name: 'winner',
            options: {
              sortThirdClickReset: true, 
              customBodyRender: ((value, tableMeta) => (
                <WinnerCell
                  winner={tableMeta.rowData[0].winner}
                  value={value}
                />
              )),
              setCellProps: (value) => {
                const winner = value.props.value;
                return {
                  className: this.getWinnerBackgroundClassName(
                    winner.didWinnerWin,
                    winner.isWinnerEliminated,
                  )
                }
              },
              sortCompare: this.sortWinners,
            }
          },
          { label: 'Points', name: 'overallPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
        ]
      }
      case "charity_2023": {
        return [
          { label: 'Original Data', name: 'originalData', options: {
            display: false,
            filter: false,
            sort: false,
          }},
          { label: 'Rank', name: 'rank', type: 'numeric', options: {
            sortThirdClickReset: true, 
          }},
          { label: 'Total', name: 'overallPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: '1-Week Change', name: 'rankChange', 
            options: {
              sortDescFirst: true, 
              sortThirdClickReset: true, 
              customBodyRender: ((value) => (
                <RankChangeCell
                  rankChange={value}
                />
              )),
            }
          },
          { label: 'Entry, Owner', name: 'displayName', options: {
            sortThirdClickReset: true, 
              customBodyRender: ((value, tableMeta) => (
                <DisplayNameCell
                  displayName={value}
                  userEntryId={tableMeta.rowData[0].userEntryId}
                  baseUrl={baseUrl}
                />
              )),
            }
          },
          { label: 'Week 2', name: 'week2Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true,
          } },
          { label: 'Week 3', name: 'week3Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true,
          } },
          { label: 'Winner', name: 'winner',
            options: {
              sortThirdClickReset: true, 
              customBodyRender: ((value, tableMeta) => (
                <WinnerCell
                  winner={tableMeta.rowData[0].winner}
                  value={value}
                />
              )),
              setCellProps: (value) => {
                const winner = value.props.value;
                return {
                  className: this.getWinnerBackgroundClassName(
                    winner.didWinnerWin,
                    winner.isWinnerEliminated,
                  )
                }
              },
              sortCompare: this.sortWinners,
            }
          },
        ]
      }
      case "golden_gerry": {
        return [
          { label: 'Original Data', name: 'originalData', options: {
            display: false,
            filter: false,
            sort: false,
          }},
          { label: 'Rank', name: 'rank', type: 'numeric', options: {
            sortThirdClickReset: true, 
          }},
          { label: '1-Week Change', name: 'rankChange', 
            options: {
              sortDescFirst: true, 
              sortThirdClickReset: true, 
              customBodyRender: ((value) => (
                <RankChangeCell
                  rankChange={value}
                />
              )),
            }
          },
          { label: 'Total', name: 'overallPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Entry, Owner', name: 'displayName', options: {
            sortThirdClickReset: true, 
              customBodyRender: ((value, tableMeta) => (
                <DisplayNameCell
                  displayName={value}
                  userEntryId={tableMeta.rowData[0].userEntryId}
                  baseUrl={baseUrl}
                />
              )),
            }
          },
          { label: 'Week 2', name: 'week2Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true,
          } },
          { label: 'Week 3', name: 'week3Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true,
          } },
          { label: 'Week 4', name: 'week4Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true,
          } },
          { label: 'Week 5', name: 'week5Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true,
          } },
          { label: 'Week 6', name: 'week6Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true,
          } },
          { label: 'Week 7', name: 'week7Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true,
          } },
          { label: 'Winner', name: 'winner',
            options: {
              sortThirdClickReset: true, 
              customBodyRender: ((value, tableMeta) => (
                <WinnerCell
                  winner={tableMeta.rowData[0].winner}
                  value={value}
                />
              )),
              setCellProps: (value) => {
                const winner = value.props.value;
                return {
                  className: this.getWinnerBackgroundClassName(
                    winner.didWinnerWin,
                    winner.isWinnerEliminated,
                  )
                }
              },
              sortCompare: this.sortWinners,
            }
          },
        ]
      }
      case "joey_2024": {
        const weeksToScore = allWeekIds.slice(0, allWeekIds.indexOf(currentWeek) + 1);
        const weeklyPoints = weeksToScore.map(weekId => ({
          label: `Week ${weekId}`,
          name: `week${weekId}Points`,
          type: 'numeric',
          options: {
            sortDescFirst: true, 
            sortThirdClickReset: true,
          },
        }));
        return [
          { label: 'Original Data', name: 'originalData', options: {
            display: false,
            filter: false,
            sort: false,
          }},
          { label: 'Rank', name: 'rank', type: 'numeric', options: {
            sortThirdClickReset: true, 
          }},
          { label: '1-Week Change', name: 'rankChange', 
            options: {
              sortDescFirst: true, 
              sortThirdClickReset: true, 
              customBodyRender: ((value) => (
                <RankChangeCell
                  rankChange={value}
                />
              )),
            }
          },
          { label: 'Total', name: 'overallPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Entry, Owner', name: 'displayName', options: {
            sortThirdClickReset: true, 
              customBodyRender: ((value, tableMeta) => (
                <DisplayNameCell
                  displayName={value}
                  userEntryId={tableMeta.rowData[0].userEntryId}
                  baseUrl={baseUrl}
                />
              )),
            }
          },
          ...weeklyPoints,
          ...(isSeasonOver ? [
            { label: 'Season Predictions', name: 'seasonPredictionPoints', type: 'numeric', options: {
              sortDescFirst: true, 
              sortThirdClickReset: true, 
            }},
          ] : []),
          { label: 'Winner', name: 'winner',
            options: {
              sortThirdClickReset: true, 
              customBodyRender: ((value, tableMeta) => (
                <WinnerCell
                  winner={tableMeta.rowData[0].winner}
                  value={value}
                />
              )),
              setCellProps: (value) => {
                const winner = value.props.value;
                return {
                  className: this.getWinnerBackgroundClassName(
                    winner.didWinnerWin,
                    winner.isWinnerEliminated,
                  )
                }
              },
              sortCompare: this.sortWinners,
            }
          },
        ]
      }
      case "jenn_2024": {
        const weeksToScore = allWeekIds.slice(0, allWeekIds.indexOf(currentWeek) + 1);
        const weeklyPoints = weeksToScore.map(weekId => ({
          label: `Week ${weekId}`,
          name: `week${weekId}Points`,
          type: 'numeric',
          options: {
            sortDescFirst: true, 
            sortThirdClickReset: true,
          },
        }));
        return [
          { label: 'Original Data', name: 'originalData', options: {
            display: false,
            filter: false,
            sort: false,
          }},
          { label: 'Rank', name: 'rank', type: 'numeric', options: {
            sortThirdClickReset: true, 
          }},
          ...(weeksToScore.length > 1 ? [
          { label: '1-Week Change', name: 'rankChange', 
            options: {
              sortDescFirst: true, 
              sortThirdClickReset: true, 
              customBodyRender: ((value) => (
                <RankChangeCell
                  rankChange={value}
                />
              )),
            }
          }] : []),
          { label: 'Total', name: 'overallPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Entry, Owner', name: 'displayName', options: {
            sortThirdClickReset: true, 
              customBodyRender: ((value, tableMeta) => (
                <DisplayNameCell
                  displayName={value}
                  userEntryId={tableMeta.rowData[0].userEntryId}
                  baseUrl={baseUrl}
                />
              )),
            }
          },
          ...weeklyPoints,
          ...(isSeasonOver ? [
            { label: 'Season Predictions', name: 'seasonPredictionPoints', type: 'numeric', options: {
              sortDescFirst: true, 
              sortThirdClickReset: true, 
            }},
          ] : []),
          { label: 'Winner', name: 'winner',
            options: {
              sortThirdClickReset: true, 
              customBodyRender: ((value, tableMeta) => (
                <WinnerCell
                  winner={tableMeta.rowData[0].winner}
                  value={value}
                />
              )),
              setCellProps: (value) => {
                const winner = value.props.value;
                return {
                  className: this.getWinnerBackgroundClassName(
                    winner.didWinnerWin,
                    winner.isWinnerEliminated,
                  )
                }
              },
              sortCompare: this.sortWinners,
            }
          },
        ]
      }
      case "golden_joan": {
        const weeksToScore = allWeekIds.slice(0, allWeekIds.indexOf(currentWeek) + 1);
        const weeklyPoints = weeksToScore.map(weekId => ({
          label: `Week ${weekId}`,
          name: `week${weekId}Points`,
          type: 'numeric',
          options: {
            sortDescFirst: true, 
            sortThirdClickReset: true,
          },
        }));
        return [
          { label: 'Original Data', name: 'originalData', options: {
            display: false,
            filter: false,
            sort: false,
          }},
          { label: 'Rank', name: 'rank', type: 'numeric', options: {
            sortThirdClickReset: true, 
          }},
          ...(weeksToScore.length > 1 ? [
          { label: '1-Week Change', name: 'rankChange', 
            options: {
              sortDescFirst: true, 
              sortThirdClickReset: true, 
              customBodyRender: ((value) => (
                <RankChangeCell
                  rankChange={value}
                />
              )),
            }
          }] : []),
          { label: 'Total', name: 'overallPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Entry, Owner', name: 'displayName', options: {
            sortThirdClickReset: true, 
              customBodyRender: ((value, tableMeta) => (
                <DisplayNameCell
                  displayName={value}
                  userEntryId={tableMeta.rowData[0].userEntryId}
                  baseUrl={baseUrl}
                />
              )),
            }
          },
          ...weeklyPoints,
          ...(isSeasonOver ? [
            { label: 'Season Predictions', name: 'seasonPredictionPoints', type: 'numeric', options: {
              sortDescFirst: true, 
              sortThirdClickReset: true, 
            }},
          ] : []),
          { label: 'Winner', name: 'winner',
            options: {
              sortThirdClickReset: true, 
              customBodyRender: ((value, tableMeta) => (
                <WinnerCell
                  winner={tableMeta.rowData[0].winner}
                  value={value}
                />
              )),
              setCellProps: (value) => {
                const winner = value.props.value;
                return {
                  className: this.getWinnerBackgroundClassName(
                    winner.didWinnerWin,
                    winner.isWinnerEliminated,
                  )
                }
              },
              sortCompare: this.sortWinners,
            }
          },
        ]
      }
    }
  }

  transformUserEntriesBySeason = (userEntriesById, season) => {
    const {
      id: seasonId,
      contestantData,
    } = season;

    switch (seasonId) {
      case "katie_2021": {
        const {
          standings,
          roseCeremonies,
          currentRoseCeremony,
          currentWeek,
        } = season;

        const roseCeremony = roseCeremonies[currentRoseCeremony];

        const {
          allEliminatedContestantIds,
          contestantIdsForPoints,
        } = roseCeremony;

        const {
          weekly,
          finalOverall,
        } = standings;

        const previousWeekStandings = weekly[currentWeek];

        const {
          overall: previousWeekOverall,
        } = previousWeekStandings;

        return finalOverall
            .map((userEntryId) => ({ 
              id: userEntryId,
              ...userEntriesById[userEntryId],
            }))
            .map((userEntry) => {
          const {
            rankings,
            scoring,
          } = userEntry;

          const winner = rankings && rankings.length > 0
            ? contestantData.byId[rankings[0]]
            : false;

          const isWinnerEliminated = winner ? allEliminatedContestantIds.includes(winner.id) : true;
          const didWinnerWin = winner ? contestantIdsForPoints.length === 1 && contestantIdsForPoints.includes(winner.id) : false;

          const overallPoints = get(scoring, ["overall"]) ?? 0;
          const week3Points = get(scoring, ["weekly", 3, "points"]) ?? 0;
          const week4Points = get(scoring, ["weekly", 4, "points"]) ?? 0;
          const week5Points = get(scoring, ["weekly", 5, "points"]) ?? 0;
          const week6Points = get(scoring, ["weekly", 6, "points"]) ?? 0;
          const week7Points = get(scoring, ["weekly", 7, "points"]) ?? 0;
          const week8Points = get(scoring, ["weekly", 8, "points"]) ?? 0;
          const week9Points = get(scoring, ["weekly", 9, "points"]) ?? 0;
          const week10Points = get(scoring, ["weekly", 10, "points"]) ?? 0;
          const seasonPredictionPoints = get(scoring, ["seasonPredictions", "points"]) ?? 0;

          const finalRank = this.getEntryRankInStandings(userEntry, finalOverall);
          const previousWeekRank = this.getEntryRankInStandings(userEntry, previousWeekOverall);
          const rankChange = previousWeekRank - finalRank;

          const displayName = this.getDisplayName(userEntry.userId);

          let gridData = {
            userEntryId: userEntry.id,
            winner: {
              ...winner,
              isWinnerEliminated: isWinnerEliminated,
              didWinnerWin: didWinnerWin,
            },
            overallPoints: overallPoints,
            week3Points: week3Points,
            week4Points: week4Points,
            week5Points: week5Points,
            week6Points: week6Points,
            week7Points: week7Points,
            week8Points: week8Points,
            week9Points: week9Points,
            week10Points: week10Points,
            seasonPredictionPoints: seasonPredictionPoints,
            rank: finalRank,
            previousRank: previousWeekRank,
            rankChange: rankChange,
            displayName: displayName,
          };

          gridData = {
            originalData: {...gridData},
            ...gridData,
          };

          return gridData;
        });
      }
      case "michelle_2021": {
        const {
          standings,
          roseCeremonies,
          currentRoseCeremony,
          currentWeek,
        } = season;

        const roseCeremony = roseCeremonies[currentRoseCeremony];

        const {
          allEliminatedContestantIds,
          contestantIdsForPoints,
        } = roseCeremony;

        const {
          weekly,
          finalOverall,
        } = standings;

        const previousWeekStandings = weekly[currentWeek];

        const {
          overall: previousWeekOverall,
        } = previousWeekStandings;

        return finalOverall
            .map((userEntryId) => ({ 
              id: userEntryId,
              ...userEntriesById[userEntryId],
            }))
            .map((userEntry) => {
          const {
            id,
            rankings,
            scoring,
          } = userEntry;

          const winner = rankings && rankings.length > 0
            ? contestantData.byId[rankings[0]]
            : false;

          const isWinnerEliminated = winner ? allEliminatedContestantIds.includes(winner.id) : true;
          const didWinnerWin = winner ? contestantIdsForPoints.length === 1 && contestantIdsForPoints.includes(winner.id) : false;

          const overallPoints = get(scoring, ["overall"]) ?? 0;
          const week3Points = get(scoring, ["weekly", 3, "points"]) ?? 0;
          const week4Points = get(scoring, ["weekly", 4, "points"]) ?? 0;
          const week5Points = get(scoring, ["weekly", 5, "points"]) ?? 0;
          const week6Points = get(scoring, ["weekly", 6, "points"]) ?? 0;
          const week7Points = get(scoring, ["weekly", 7, "points"]) ?? 0;
          const week8Points = get(scoring, ["weekly", 8, "points"]) ?? 0;
          const week9Points = get(scoring, ["weekly", 9, "points"]) ?? 0;
          const seasonPredictionPoints = get(scoring, ["seasonPredictions", "points"]) ?? 0;

          const finalRank = this.getEntryRankInStandings(userEntry, finalOverall);
          const previousWeekRank = this.getEntryRankInStandings(userEntry, previousWeekOverall);
          const rankChange = previousWeekRank - finalRank;

          const displayName = this.getDisplayName(userEntry.userId);

          let gridData = {
            userEntryId: userEntry.id,
            winner: {
              ...winner,
              isWinnerEliminated: isWinnerEliminated,
              didWinnerWin: didWinnerWin,
            },
            overallPoints: overallPoints,
            week2Points: week3Points,
            week3Points: week3Points,
            week4Points: week4Points,
            week5Points: week5Points,
            week6Points: week6Points,
            week7Points: week7Points,
            week8Points: week8Points,
            week9Points: week9Points,
            seasonPredictionPoints: seasonPredictionPoints,
            rank: finalRank,
            previousRank: previousWeekRank,
            rankChange: rankChange,
            displayName: displayName,
          };

          gridData = {
            originalData: {...gridData},
            ...gridData,
          };

          return gridData;
        });
      }
      case "clayton_2022": {
        const {
          standings,
          roseCeremonies,
          currentRoseCeremony,
          currentWeek,
        } = season;

        const roseCeremony = roseCeremonies[currentRoseCeremony];

        const {
          allEliminatedContestantIds,
          contestantIdsForPoints,
        } = roseCeremony;

        const {
          weekly,
          finalOverall,
        } = standings;

        const previousWeekStandings = weekly[currentWeek];

        const {
          overall: previousWeekOverall,
        } = previousWeekStandings;

        return finalOverall
          .map((userEntryId, index) => ({ 
            id: userEntryId,
            index: index,
            ...userEntriesById[userEntryId],
          }))
          .map((userEntry) => {

          const {
            id,
            rankings,
            scoring,
          } = userEntry;

          const winner = rankings && rankings.length > 0
            ? contestantData.byId[rankings[0]]
            : false;

          const isWinnerEliminated = winner ? allEliminatedContestantIds.includes(winner.id) : true;
          const didWinnerWin = winner ? contestantIdsForPoints.length === 1 && contestantIdsForPoints.includes(winner.id) : false;

          const overallPoints = get(scoring, ["overall"]) ?? 0;
          const week3Points = get(scoring, ["weekly", 3, "points"]) ?? 0;
          const week4Points = get(scoring, ["weekly", 4, "points"]) ?? 0;
          const week5Points = get(scoring, ["weekly", 5, "points"]) ?? 0;
          const week6Points = get(scoring, ["weekly", 6, "points"]) ?? 0;
          const week7Points = get(scoring, ["weekly", 7, "points"]) ?? 0;
          const week8Points = get(scoring, ["weekly", 8, "points"]) ?? 0;
          const week9Points = get(scoring, ["weekly", 9, "points"]) ?? 0;
          const week10Points = get(scoring, ["weekly", 10, "points"]) ?? 0;
          const seasonPredictionPoints = get(scoring, ["seasonPredictions", "points"]) ?? 0;

          const finalRank = this.getEntryRankInStandings(userEntry, finalOverall);
          const previousWeekRank = this.getEntryRankInStandings(userEntry, previousWeekOverall);
          const rankChange = previousWeekRank - finalRank;

          const displayName = this.getDisplayName(userEntry.userId);

          let gridData = {
            userEntryId: userEntry.id,
            winner: {
              ...winner,
              isWinnerEliminated: isWinnerEliminated,
              didWinnerWin: didWinnerWin,
            },
            overallPoints: overallPoints,
            week3Points: week3Points,
            week4Points: week4Points,
            week5Points: week5Points,
            week6Points: week6Points,
            week7Points: week7Points,
            week8Points: week8Points,
            week9Points: week9Points,
            week10Points: week10Points,
            seasonPredictionPoints: seasonPredictionPoints,
            rank: finalRank,
            previousRank: previousWeekRank,
            rankChange: rankChange,
            displayName: displayName,
          };

          gridData = {
            originalData: {...gridData},
            ...gridData,
          };

          return gridData;
        });
      }
      case "gabby_rachel_2022": {
        const {
          standings,
        } = season;

        /*
        const roseCeremony = roseCeremonies[currentRoseCeremony];

        const {
          allEliminatedContestantIds,
          contestantIdsForPoints,
        } = roseCeremony;

        const {
          weekly,
        } = standings;

        const currentWeekStandings = weekly[currentWeek];
        const previousWeekStandings = weekly[currentWeek - 1];

        const {
          overall: currentWeekOverall,
        } = currentWeekStandings;

        const {
          overall: previousWeekOverall,
        } = previousWeekStandings;
        */

        // return currentWeekStandings 
        return Object.keys(userEntriesById)
          .map((userEntryId, index) => ({ 
            id: userEntryId,
            index: index,
            ...userEntriesById[userEntryId],
          }))
          .map((userEntry) => {

          const {
            id,
            rankings,
            scoring,
          } = userEntry;

          const winner = rankings && rankings.length > 0
            ? contestantData.byId[rankings[0]]
            : false;

          /*
          const isWinnerEliminated = winner ? allEliminatedContestantIds.includes(winner.id) : true;
          const didWinnerWin = winner ? contestantIdsForPoints.length === 1 && contestantIdsForPoints.includes(winner.id) : false;

          const overallPoints = get(scoring, ["overall"]) ?? 0;
          const week3Points = get(scoring, ["weekly", 3, "points"]) ?? 0;
          const week4Points = get(scoring, ["weekly", 4, "points"]) ?? 0;
          const week5Points = get(scoring, ["weekly", 5, "points"]) ?? 0;
          const week6Points = get(scoring, ["weekly", 6, "points"]) ?? 0;
          const week7Points = get(scoring, ["weekly", 7, "points"]) ?? 0;

          const currentWeekRank = this.getEntryRankInStandings(userEntry, currentWeekOverall);
          const previousWeekRank = this.getEntryRankInStandings(userEntry, previousWeekOverall);
          const rankChange = previousWeekRank - currentWeekRank;
          */

          const displayName = this.getDisplayName(userEntry.userId);

          let gridData = {
            userEntryId: userEntry.id,
            winner: {
              ...winner,
              /*
              isWinnerEliminated: isWinnerEliminated,
              didWinnerWin: didWinnerWin,
              */
            },
            /*
            overallPoints: overallPoints,
            week3Points: week3Points,
            week4Points: week4Points,
            week5Points: week5Points,
            week6Points: week6Points,
            week7Points: week7Points,
            rank: currentWeekRank,
            previousRank: previousWeekRank,
            rankChange: rankChange,
            */
            displayName: displayName,
          };

          gridData = {
            originalData: {...gridData},
            ...gridData,
          };

          return gridData;
        });
      }
      case "zach_2023": {
        const {
          standings,
          roseCeremonies,
          currentRoseCeremony,
          currentWeek,
        } = season;

        const roseCeremony = roseCeremonies[currentRoseCeremony];

        const {
          allEliminatedContestantIds,
          contestantIdsForPoints,
        } = roseCeremony;

        const {
          weekly,
        } = standings;

        const currentWeekStandings = weekly[currentWeek];
        //const previousWeekStandings = weekly[currentWeek - 1];

        const {
          overall: currentWeekOverall,
        } = currentWeekStandings;

        // const {
        //   overall: previousWeekOverall,
        // } = previousWeekStandings;

        return currentWeekOverall
          .map((userEntryId, index) => ({ 
            id: userEntryId,
            index: index,
            ...userEntriesById[userEntryId],
          }))
          .map((userEntry) => {

          const {
            id,
            rankings,
            scoring,
          } = userEntry;

          const winner = rankings && rankings.length > 0
            ? contestantData.byId[rankings[0]]
            : false;

          const isWinnerEliminated = winner ? allEliminatedContestantIds.includes(winner.id) : true;
          const didWinnerWin = winner ? contestantIdsForPoints.length === 1 && contestantIdsForPoints.includes(winner.id) : false;

          const overallPoints = get(scoring, ["overall"]) ?? 0;
          const week2Points = get(scoring, ["weekly", 2, "points"]) ?? 0;
          /*
          const week3Points = get(scoring, ["weekly", 3, "points"]) ?? 0;
          const week4Points = get(scoring, ["weekly", 4, "points"]) ?? 0;
          const week5Points = get(scoring, ["weekly", 5, "points"]) ?? 0;
          const week6Points = get(scoring, ["weekly", 6, "points"]) ?? 0;
          const week7Points = get(scoring, ["weekly", 7, "points"]) ?? 0;

          const previousWeekRank = this.getEntryRankInStandings(userEntry, previousWeekOverall);
          const rankChange = previousWeekRank - currentWeekRank;
          */
          const currentWeekRank = this.getEntryRankInStandings(userEntry, currentWeekOverall);

          const displayName = this.getDisplayName(userEntry.userId);

          let gridData = {
            userEntryId: userEntry.id,
            winner: {
              ...winner,
              isWinnerEliminated: isWinnerEliminated,
              didWinnerWin: didWinnerWin,
            },
            overallPoints: overallPoints,
            week2Points: week2Points,
            /*
            week3Points: week3Points,
            week4Points: week4Points,
            week5Points: week5Points,
            week6Points: week6Points,
            week7Points: week7Points,
            previousRank: previousWeekRank,
            rankChange: rankChange,
            */
            rank: currentWeekRank,
            displayName: displayName,
          };

          gridData = {
            originalData: {...gridData},
            ...gridData,
          };

          return gridData;
        });
      }
      case "charity_2023": {
        const {
          standings,
          roseCeremonies,
          currentRoseCeremony,
          currentWeek,
        } = season;

        const roseCeremony = roseCeremonies[currentRoseCeremony];

        const {
          allEliminatedContestantIds,
          contestantIdsForPoints,
        } = roseCeremony;

        const {
          weekly,
        } = standings;

        const currentWeekStandings = weekly[currentWeek];
        const previousWeekStandings = weekly[currentWeek - 1];

        const {
          overall: currentWeekOverall,
        } = currentWeekStandings;

        const {
          overall: previousWeekOverall,
        } = previousWeekStandings;

        return currentWeekOverall
          .map((userEntryId, index) => ({ 
            id: userEntryId,
            index: index,
            ...userEntriesById[userEntryId],
          }))
          .map((userEntry) => {

          const {
            id,
            rankings,
            scoring,
          } = userEntry;

          const winner = rankings && rankings.length > 0
            ? contestantData.byId[rankings[0]]
            : false;

          const isWinnerEliminated = winner ? allEliminatedContestantIds.includes(winner.id) : true;
          const didWinnerWin = winner ? contestantIdsForPoints.length === 1 && contestantIdsForPoints.includes(winner.id) : false;

          const overallPoints = get(scoring, ["overall"]) ?? 0;
          const week2Points = get(scoring, ["weekly", 2, "points"]) ?? 0;
          const week3Points = get(scoring, ["weekly", 3, "points"]) ?? 0;
          /*
          const week4Points = get(scoring, ["weekly", 4, "points"]) ?? 0;
          const week5Points = get(scoring, ["weekly", 5, "points"]) ?? 0;
          const week6Points = get(scoring, ["weekly", 6, "points"]) ?? 0;
          const week7Points = get(scoring, ["weekly", 7, "points"]) ?? 0;
          */

          const currentWeekRank = this.getEntryRankInStandings(userEntry, currentWeekOverall);
          const previousWeekRank = this.getEntryRankInStandings(userEntry, previousWeekOverall);
          const rankChange = previousWeekRank - currentWeekRank;

          const displayName = this.getDisplayName(userEntry.userId);

          let gridData = {
            userEntryId: userEntry.id,
            winner: {
              ...winner,
              isWinnerEliminated: isWinnerEliminated,
              didWinnerWin: didWinnerWin,
            },
            overallPoints: overallPoints,
            week2Points: week2Points,
            week3Points: week3Points,
            /*
            week4Points: week4Points,
            week5Points: week5Points,
            week6Points: week6Points,
            week7Points: week7Points,
            */
            previousRank: previousWeekRank,
            rankChange: rankChange,
            rank: currentWeekRank,
            displayName: displayName,
          };

          gridData = {
            originalData: {...gridData},
            ...gridData,
          };

          return gridData;
        });
      }
      case "golden_gerry": {
        const {
          standings,
          roseCeremonies,
          currentRoseCeremony,
          currentWeek,
        } = season;

        const roseCeremony = roseCeremonies[currentRoseCeremony];

        const {
          allEliminatedContestantIds,
          contestantIdsForPoints,
        } = roseCeremony;

        const {
          weekly,
        } = standings;

        const currentWeekStandings = weekly[currentWeek];
        const previousWeekStandings = weekly[currentWeek - 1];

        const {
          overall: currentWeekOverall,
        } = currentWeekStandings;

        const {
          overall: previousWeekOverall,
        } = previousWeekStandings;

        return currentWeekOverall
          .map((standingsEntry, index) => ({ 
            index: index,
            ...standingsEntry,
            ...userEntriesById[standingsEntry.id],
          }))
          .map((userEntry) => {

          const {
            id,
            rank,
            rankings,
            scoring,
          } = userEntry;

          const winner = rankings && rankings.length > 0
            ? contestantData.byId[rankings[0]]
            : false;

          const isWinnerEliminated = winner ? allEliminatedContestantIds.includes(winner.id) : true;
          const didWinnerWin = winner ? contestantIdsForPoints.length === 1 && contestantIdsForPoints.includes(winner.id) : false;

          const overallPoints = get(scoring, ["overall"]) ?? 0;
          const week2Points = get(scoring, ["weekly", 2, "points"]) ?? 0;
          const week3Points = get(scoring, ["weekly", 3, "points"]) ?? 0;
          const week4Points = get(scoring, ["weekly", 4, "points"]) ?? 0;
          const week5Points = get(scoring, ["weekly", 5, "points"]) ?? 0;
          const week6Points = get(scoring, ["weekly", 6, "points"]) ?? 0;
          const week7Points = get(scoring, ["weekly", 7, "points"]) ?? 0;

          const currentWeekRank = rank;
          const previousWeekRank = this.getEntryRankInDetailedStandings(userEntry, previousWeekOverall);
          const rankChange = previousWeekRank - currentWeekRank;

          const displayName = this.getDisplayName(userEntry.userId);

          let gridData = {
            userEntryId: userEntry.id,
            winner: {
              ...winner,
              isWinnerEliminated: isWinnerEliminated,
              didWinnerWin: didWinnerWin,
            },
            overallPoints: overallPoints,
            week2Points: week2Points,
            week3Points: week3Points,
            week4Points: week4Points,
            week5Points: week5Points,
            week6Points: week6Points,
            week7Points: week7Points,
            previousRank: previousWeekRank,
            rankChange: rankChange,
            rank: currentWeekRank,
            displayName: displayName,
          };

          gridData = {
            originalData: {...gridData},
            ...gridData,
          };

          return gridData;
        });
      }
      case "joey_2024": {
        const {
          standings,
          roseCeremonies,
          currentRoseCeremony,
          currentWeek,
          allWeekIds,
          isSeasonOver = false,
        } = season;

        const weeksToScore = allWeekIds.slice(0, allWeekIds.indexOf(currentWeek) + 1);
        const roseCeremony = roseCeremonies[currentRoseCeremony];

        const {
          allEliminatedContestantIds,
          contestantIdsForPoints,
        } = roseCeremony;

        const {
          weekly,
          withSeasonPredictions,
        } = standings;

        const currentWeekStandings = !isSeasonOver ? weekly[currentWeek] : withSeasonPredictions;
        const previousWeekStandings = !isSeasonOver ? weekly[currentWeek - 1] : weekly[currentWeek];

        const {
          overall: currentWeekOverall,
        } = currentWeekStandings;

        const {
          overall: previousWeekOverall,
        } = previousWeekStandings;

        return currentWeekOverall
          .map((standingsEntry, index) => ({ 
            index: index,
            ...standingsEntry,
            ...userEntriesById[standingsEntry.id],
          }))
          .map((userEntry) => {

          const {
            id,
            rank,
            rankings,
            scoring,
          } = userEntry;

          const winner = rankings && rankings.length > 0
            ? contestantData.byId[rankings[0]]
            : false;

          const isWinnerEliminated = winner ? allEliminatedContestantIds.includes(winner.id) : true;
          const didWinnerWin = winner ? contestantIdsForPoints.length === 1 && contestantIdsForPoints.includes(winner.id) : false;

          let gridData =  {};

          const overallPoints = get(scoring, ["overall"]) ?? 0;
          weeksToScore.forEach(weekId => {
            const weekScore = get(scoring, ["weekly", weekId, "points"]) ?? 0;
            gridData[`week${weekId}Points`] = weekScore;
          });
          const seasonPredictionPoints = get(scoring, ["seasonPredictions", "points"]) ?? 0;

          const currentWeekRank = rank;
          const previousWeekRank = this.getEntryRankInDetailedStandings(userEntry, previousWeekOverall);
          const rankChange = previousWeekRank - currentWeekRank;

          const displayName = this.getDisplayName(userEntry.userId);

          gridData = {
            ...gridData,
            userEntryId: userEntry.id,
            winner: {
              ...winner,
              isWinnerEliminated: isWinnerEliminated,
              didWinnerWin: didWinnerWin,
            },
            overallPoints: overallPoints,
            seasonPredictionPoints: seasonPredictionPoints,
            previousRank: previousWeekRank,
            rankChange: rankChange,
            rank: currentWeekRank,
            displayName: displayName,
          };

          gridData = {
            originalData: {...gridData},
            ...gridData,
          };

          return gridData;
        });
      }
      case "jenn_2024": {
        const {
          standings,
          roseCeremonies,
          currentRoseCeremony,
          currentWeek,
          allWeekIds,
          isSeasonOver = false,
        } = season;

        const weeksToScore = allWeekIds.slice(0, allWeekIds.indexOf(currentWeek) + 1);
        const roseCeremony = roseCeremonies[currentRoseCeremony];

        const {
          allEliminatedContestantIds,
          contestantIdsForPoints,
        } = roseCeremony;

        const {
          weekly,
          withSeasonPredictions,
        } = standings;

        const currentWeekStandings = !isSeasonOver ? weekly[currentWeek] : withSeasonPredictions;
        const previousWeekStandings = !isSeasonOver && weeksToScore.length > 1 ? weekly[currentWeek - 1] : weekly[currentWeek];

        const {
          overall: currentWeekOverall,
        } = currentWeekStandings;

        const {
          overall: previousWeekOverall,
        } = previousWeekStandings;

        return currentWeekOverall
          .map((standingsEntry, index) => ({ 
            index: index,
            ...standingsEntry,
            ...userEntriesById[standingsEntry.id],
          }))
          .map((userEntry) => {

          const {
            id,
            rank,
            rankings,
            scoring,
          } = userEntry;

          const winner = rankings && rankings.length > 0
            ? contestantData.byId[rankings[0]]
            : false;

          const isWinnerEliminated = winner ? allEliminatedContestantIds.includes(winner.id) : true;
          const didWinnerWin = winner ? contestantIdsForPoints.length === 1 && contestantIdsForPoints.includes(winner.id) : false;

          let gridData =  {};

          const overallPoints = get(scoring, ["overall"]) ?? 0;
          weeksToScore.forEach(weekId => {
            const weekScore = get(scoring, ["weekly", weekId, "points"]) ?? 0;
            gridData[`week${weekId}Points`] = weekScore;
          });
          const seasonPredictionPoints = get(scoring, ["seasonPredictions", "points"]) ?? 0;

          const currentWeekRank = rank;
          const previousWeekRank = this.getEntryRankInDetailedStandings(userEntry, previousWeekOverall);
          const rankChange = previousWeekRank - currentWeekRank;

          const displayName = this.getDisplayName(userEntry.userId);

          gridData = {
            ...gridData,
            userEntryId: userEntry.id,
            winner: {
              ...winner,
              isWinnerEliminated: isWinnerEliminated,
              didWinnerWin: didWinnerWin,
            },
            overallPoints: overallPoints,
            seasonPredictionPoints: seasonPredictionPoints,
            previousRank: previousWeekRank,
            rankChange: rankChange,
            rank: currentWeekRank,
            displayName: displayName,
          };

          gridData = {
            originalData: {...gridData},
            ...gridData,
          };

          return gridData;
        });
      }
      case "golden_joan": {
        const {
          standings,
          roseCeremonies,
          currentRoseCeremony,
          currentWeek,
          allWeekIds,
          isSeasonOver = false,
        } = season;

        const weeksToScore = allWeekIds.slice(0, allWeekIds.indexOf(currentWeek) + 1);
        const roseCeremony = roseCeremonies[currentRoseCeremony];

        const {
          allEliminatedContestantIds,
          contestantIdsForPoints,
        } = roseCeremony;

        const {
          weekly,
          withSeasonPredictions,
        } = standings;

        const currentWeekStandings = !isSeasonOver ? weekly[currentWeek] : withSeasonPredictions;
        const previousWeekStandings = !isSeasonOver && weeksToScore.length > 1 ? weekly[currentWeek - 1] : weekly[currentWeek];

        const {
          overall: currentWeekOverall,
        } = currentWeekStandings;

        const {
          overall: previousWeekOverall,
        } = previousWeekStandings;

        return currentWeekOverall
          .map((standingsEntry, index) => ({ 
            index: index,
            ...standingsEntry,
            ...userEntriesById[standingsEntry.id],
          }))
          .map((userEntry) => {

          const {
            id,
            rank,
            rankings,
            scoring,
          } = userEntry;

          const winner = rankings && rankings.length > 0
            ? contestantData.byId[rankings[0]]
            : false;

          const isWinnerEliminated = winner ? allEliminatedContestantIds.includes(winner.id) : true;
          const didWinnerWin = winner ? contestantIdsForPoints.length === 1 && contestantIdsForPoints.includes(winner.id) : false;

          let gridData =  {};

          const overallPoints = get(scoring, ["overall"]) ?? 0;
          weeksToScore.forEach(weekId => {
            const weekScore = get(scoring, ["weekly", weekId, "points"]) ?? 0;
            gridData[`week${weekId}Points`] = weekScore;
          });
          const seasonPredictionPoints = get(scoring, ["seasonPredictions", "points"]) ?? 0;

          const currentWeekRank = rank;
          const previousWeekRank = this.getEntryRankInDetailedStandings(userEntry, previousWeekOverall);
          const rankChange = previousWeekRank - currentWeekRank;

          const displayName = this.getDisplayName(userEntry.userId);

          gridData = {
            ...gridData,
            userEntryId: userEntry.id,
            winner: {
              ...winner,
              isWinnerEliminated: isWinnerEliminated,
              didWinnerWin: didWinnerWin,
            },
            overallPoints: overallPoints,
            seasonPredictionPoints: seasonPredictionPoints,
            previousRank: previousWeekRank,
            rankChange: rankChange,
            rank: currentWeekRank,
            displayName: displayName,
          };

          gridData = {
            originalData: {...gridData},
            ...gridData,
          };

          return gridData;
        });
      }
    }
  };


  isLoaded = () => {
    const {
      seasons,
      userEntriesById,
      myUserEntryAll,
      myUserEntryById,
    } = this.props;
    const allDataLoaded = isLoaded(
      seasons,
      userEntriesById,
      myUserEntryAll,
      myUserEntryById
    );

    if (allDataLoaded) {
      const usersNotLoaded = Object.values(userEntriesById).some((entry) => typeof entry.userId !== "object");
      return allDataLoaded && !usersNotLoaded;
    }
    
    return allDataLoaded;
  }

  handleTabChange = (event, newTab) => {
    this.setState({
      selectedTabIndex: newTab,
    });
  };

  isUserSetup = () => {
    const {
      user,
    } = this.props;

    return user && (user.firstName || user.lastName);
  };

  getEntryRankInStandings = (userEntry, standings) => {
    const {
      id,
    } = userEntry;

    return standings.indexOf(id) + 1;
  };

  getEntryRankInDetailedStandings = (userEntry, standings) => {
    const {
      id,
    } = userEntry;

    const entryInStandings = standings.find((entry) => entry.id === id); 
    if (entryInStandings) {
      return entryInStandings.rank;
    } else {
      return standings.length + 1;
    }
  };

  render() {
    // Functions
    const {
      onSettingsClick,
    } = this.props;

    // Styling
    const { classes } = this.props;

    const {
      selectedTabIndex,
    } = this.state;

    const {
      seasons,
      userEntriesById,
      myUserEntryAll,
      myUserEntryById,
    } = this.props;

    const params = get(this.props, "match.params");
    const {
      seasonId,
    } = params;

    if (!this.isLoaded()) {
      return <Loader />;
    }

    const myUserEntry = userEntries.getMyUserEntryFromAllOrDefault(myUserEntryAll);
    const hasEntry = !(myUserEntry && Object.keys(myUserEntry).length === 0 && myUserEntry.constructor === Object)
    const isUserComplete = this.isUserSetup();

    const season = get(seasons, seasonId);

    const tableData = this.transformUserEntriesBySeason(userEntriesById, season);
    const tableColumns = this.getColumnsBySeason(season);

    const myEntryData = hasEntry ? tableData.filter((row) => row.userEntryId === myUserEntry.id) : null;

    return (
      <>
        <Hidden mdUp>
          <Box>
            <Collapse
              in={!isUserComplete}
              timeout="auto"
              unmountOnExit
            >
              <Box mb={2}>
                <Alert
                  severity={"warning"}
                  action={
                    <Button
                      size="small"
                      color={"primary"}
                      variant={"contained"}
                      disableElevation
                      onClick={onSettingsClick}
                    >
                      Edit Profile 
                    </Button>
                  }
                >
                  {isUserComplete 
                    ? <strong>Your profile is all set up!</strong>
                    : <strong>You still need to set up your profile!</strong>
                  }
                </Alert>
              </Box>
            </Collapse>
            <Paper>
              <Box pt={2} px={4}>
                <Tabs
                  indicatorColor="primary"
                  textColor="primary"
                  value={selectedTabIndex}
                  onChange={this.handleTabChange}
                  centered
                >
                  <Tab label="Overall" />
                </Tabs>
                <TabPanel value={selectedTabIndex} index={0}>
                  {hasEntry && (
                    <MUIDataTable
                      title="My Entry:"
                      columns={tableColumns}
                      data={myEntryData}
                      options={{
                        elevation: 0,
                        pagination: false,
                        download: false,
                        print: false,
                        filter: false,
                        viewColumns: false,
                        search: false,
                        sort: false,
                        rowHover: false,
                        tableBodyMaxHeight: "100%",
                        selectableRows: "none",
                        responsive: "standard",
                        setRowProps: () => {
                          return {
                            className: classes.myEntry,
                          }
                        },
                        setTableProps: () => {
                          return {
                            size: "small",
                          };
                        },
                      }}
                    />
                  )}
                  <MUIDataTable
                    title="Standings:"
                    columns={tableColumns}
                    data={tableData}
                    options={{
                      elevation: 0,
                      pagination: false,
                      download: false,
                      print: false,
                      filter: false,
                      viewColumns: false,
                      search: false,
                      tableBodyMaxHeight: "100%",
                      selectableRows: "none",
                      responsive: "standard",
                      setRowProps: (row, dataIndex, rowIndex) => {
                        if (hasEntry && myEntryData.length) {
                          if (myEntryData[0].userEntryId === row[0].userEntryId) {
                            return {
                              className: classes.myEntry,
                            }
                          }
                        }
                        return {};
                      },
                      setTableProps: () => {
                        return {
                          size: "small",
                        };
                      },
                    }}
                  />
                </TabPanel>
              </Box>
            </Paper>
          </Box>
        </Hidden>
        <Hidden smDown>
          <Box p={2.5}>
            <Collapse
              in={!isUserComplete}
              timeout="auto"
              unmountOnExit
            >
              <Box mb={2}>
                <Alert
                  severity={"warning"}
                  action={
                    <Button
                      size="small"
                      color={"primary"}
                      variant={"contained"}
                      disableElevation
                      onClick={onSettingsClick}
                    >
                      Edit Profile 
                    </Button>
                  }
                >
                  {isUserComplete 
                    ? <strong>Your profile is all set up!</strong>
                    : <strong>You still need to set up your profile!</strong>
                  }
                </Alert>
              </Box>
            </Collapse>
            <Paper>
              <Box pt={2} px={4}>
                <Tabs
                  indicatorColor="primary"
                  textColor="primary"
                  value={selectedTabIndex}
                  onChange={this.handleTabChange}
                  centered
                >
                  <Tab label="Overall" />
                </Tabs>
                <TabPanel value={selectedTabIndex} index={0}>
                  {hasEntry && (
                    <MUIDataTable
                      title="My Entry:"
                      columns={tableColumns}
                      data={myEntryData}
                      options={{
                        elevation: 0,
                        pagination: false,
                        download: false,
                        print: false,
                        filter: false,
                        viewColumns: false,
                        search: false,
                        sort: false,
                        rowHover: false,
                        tableBodyMaxHeight: "100%",
                        selectableRows: "none",
                        responsive: "standard",
                        setRowProps: () => {
                          return {
                            className: classes.myEntry,
                          }
                        },
                        setTableProps: () => {
                          return {
                            size: "small",
                          };
                        },
                      }}
                    />
                  )}
                  <MUIDataTable
                    title="Standings:"
                    columns={tableColumns}
                    data={tableData}
                    options={{
                      elevation: 0,
                      pagination: false,
                      download: false,
                      print: false,
                      filter: false,
                      viewColumns: false,
                      search: false,
                      tableBodyMaxHeight: "100%",
                      selectableRows: "none",
                      responsive: "standard",
                      setRowProps: (row, dataIndex, rowIndex) => {
                        if (hasEntry && myEntryData.length) {
                          if (myEntryData[0].userEntryId === row[0].userEntryId) {
                            return {
                              className: classes.myEntry,
                            }
                          }
                        }
                        return {};
                      },
                      setTableProps: () => {
                        return {
                          size: "small",
                        };
                      },
                    }}
                  />
                </TabPanel>
              </Box>
            </Paper>
          </Box>
        </Hidden>
      </>
    );
  }
}

function mapStateToProps(state, props) {
  const params = get(props, "match.params");
  const {
    seasonId,
  } = params;

  return {
    seasons: state.firestore.data.seasons,
    userEntriesById: populate(state.firestore, `${seasonId}#userEntries`, firestoreConstants.populateUserIds),
    myUserEntryById: populate(state.firestore, `${seasonId}#${myUserEntry}`, firestoreConstants.populateUserIds),
    myUserEntryAll: state.firestore.ordered[`${seasonId}#${myUserEntry}`],
  };
}

function registerFirestoreListeners(props) {
  const params = get(props, "match.params");
  const {
    seasonId,
  } = params;

  return [
    {
      collection: "seasons",
      doc: seasonId,
    },
    {
      collection: "seasons",
      doc: seasonId,
      subcollections: [{
        collection: "user_entries",
      }],
      populates: firestoreConstants.populateUserIds,
      storeAs: `${seasonId}#userEntries`,
    },
    {
      collection: "seasons",
      doc: seasonId,
      subcollections: [{
        collection: "user_entries",
        where: ["userId", "==", props.user.uid],
      }],
      populates: firestoreConstants.populateUserIds,
      storeAs: `${seasonId}#${myUserEntry}`,
    },
  ];
}

export default compose(
  withStyles(styles),
  withRouter,
  firestoreConnect(registerFirestoreListeners),
  connect(mapStateToProps),
)(StandingsPage);
