import firebase from "firebase/app";

import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/performance";

import {
  useFirebaseEmulator,
} from "./data/season-constants";

const firebaseConfig = {
  apiKey: "AIzaSyD-egqrT6O26wlytZ5yuSLHwqRpqlvu_cs",
  authDomain: "alliesfantasybachelor.firebaseapp.com",
  projectId: "alliesfantasybachelor",
  storageBucket: "alliesfantasybachelor.appspot.com",
  messagingSenderId: "428202460433",
  appId: "1:428202460433:web:e1d1aee935f03279a6f8b0",
  measurementId: "G-83JK9HMSPV"
};

firebase.initializeApp(firebaseConfig);

export default firebase;

const auth = firebase.auth();
const firestore = firebase.firestore();
const storage = firebase.storage();

// eslint-disable-next-line no-restricted-globals
if (useFirebaseEmulator && window.location.hostname === 'localhost') {
  firestore.useEmulator('localhost', 8080);
  auth.useEmulator('http://localhost:9099/', { disableWarnings: true });
  storage.useEmulator("localhost", 9199);
}

export { auth, firestore, storage }; 

export const performance = firebase.performance();

export let analytics;

if (process.env.NODE_ENV !== "test") {
  analytics = firebase.analytics();
}

export const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  enableClaims: true
}

export const firestoreConstants = {
  populateUserIds: [{
    child: "userId",
    root: "users",
  }],
};
